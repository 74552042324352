/**
 * A set of filtering conditions to apply over a resource list.
 */
export class DataFilter {
  private conditions: DataFilterCondition[] = [];

  /**
   * How it will join the conditions, either AND or OR. Defaults to AND.
   */
  private joinOperator = DataJoinOperator.AND;

  /**
   * Sets the join operator to OR.
   */
  or(): void {
    this.joinOperator = DataJoinOperator.OR;
  }

  /**
   * Other filters to join with using an AND condition.
   */
  others: DataFilter[] = [];

  /**
   * Add another data filter to be joined with this one using an AND condition.
   *
   * @param other another filter to concatenate
   */
  and(other: DataFilter): void {
    this.others.push(other);
  }

  /**
   * Append a condition to the filter.
   *
   * @param operator The operator to apply.
   * @param attributes The attribute (or attributes) to filter.
   * @param values The value (or values) to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  where(operator: DataFilterOperator, attributes: string | string[], values: any | any[], datatype: string): DataFilter {
    const condition = new DataFilterCondition();

    condition.operator = operator;
    condition.attributes = Array.isArray(attributes) ? attributes : [attributes];
    condition.values = Array.isArray(values) ? values : [values];
    condition.datatype = datatype;

    this.conditions.push(condition);
    return this;
  }

  /**
   * Shorthand to append an "equals" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param value The value to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  equals(attributes: string | string[], value: any, datatype: string): DataFilter {
    return this.where(DataFilterOperator.EQUALS, attributes, value, datatype);
  }

  /**
   * Shorthand to append a "not equal" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param value The value to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  notEqual(attributes: string | string[], value: any, datatype: string): DataFilter {
    return this.where(DataFilterOperator.NOT_EQUAL, attributes, value, datatype);
  }

  /**
   * Shorthand to append a "greater or equal" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param value The value to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  greaterOrEqual(attributes: string | string[], value: any, datatype: string): DataFilter {
    return this.where(DataFilterOperator.GREATER_OR_EQUAL, attributes, value, datatype);
  }

  /**
   * Shorthand to append a "less or equal" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param value The value to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  lessOrEqual(attributes: string | string[], value: any, datatype: string): DataFilter {
    return this.where(DataFilterOperator.LESS_OR_EQUAL, attributes, value, datatype);
  }

  /**
   * Shorthand to append an "in" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param values The values to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  in(attributes: string | string[], values: any[], datatype: string): DataFilter {
    if (values.length > 0) {
      return this.where(DataFilterOperator.IN, attributes, [...values], datatype);
    }

    return this;
  }

  /**
   * Shorthand to append a "like" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @param value The value to filter for.
   * @param datatype The datatype of the values to filter.
   * @returns The filter itself.
   */
  like(attributes: string | string[], value: any, datatype: string): DataFilter {
    return this.where(DataFilterOperator.LIKE, attributes, value, datatype);
  }

  /**
   * Shorthand to append an "is null" condition.
   *
   * @param attributes The attribute (or attributes) to filter.
   * @returns The filter itself.
   */
  isNull(attributes: string | string[]): DataFilter {
    return this.where(DataFilterOperator.IS_NULL, attributes, 'null', 'string');
  }

  /**
   * Encode the whole filtering expression to the format that is expected by the rest services:
   * <attribute1[+attribute2...]>|<operator>|<value1[;value2...]>|<datatype>
   *
   * @returns The encoded filtering expression.
   */
  encode(): string {
    let result = this.conditions
      .map((condition) => {
        const parts = [];
        parts.push(condition.attributes.join('+'));
        parts.push(condition.operator);
        parts.push(condition.values.join(';'));
        parts.push(condition.datatype);
        return parts.join('|');
      })
      .join(',');

    if (this.joinOperator === DataJoinOperator.OR) {
      result = '[' + result + ']';
    }

    if (this.others.length > 0) {
      result = result.concat(
        `,${this.others
          .map((o) => o.encode())
          .filter((f) => f !== '')
          .join(',')}`,
      );
    }

    return result;
  }

  /**
   * Encode the whole filtering expression as an URI component.
   *
   * @returns The URI encoded filtering expression.
   */
  encodeURIComponent(): string {
    const expression = this.encode();
    return encodeURIComponent(expression);
  }

  /**
   * Override toString method to properly encode the filter object as a string.
   *
   * @returns the encoded filter value
   */
  toString(): string {
    return this.encodeURIComponent();
  }
}

/**
 * A single filtering condition over a single or several attributes.
 */
export class DataFilterCondition {
  attributes: string[] = [];

  operator: DataFilterOperator | null = null;

  values: any[] = [];

  datatype: string | null = null;
}

/**
 * The possible operators to apply on a filtering condition.
 */
export enum DataFilterOperator {
  EQUALS = 'eq',
  NOT_EQUAL = 'neq',
  GREATER_OR_EQUAL = 'ge',
  LESS_OR_EQUAL = 'le',
  IN = 'in',
  LIKE = 'like',
  IS_NULL = 'isnull',
}

/**
 * Options for joining each condition.
 */
export enum DataJoinOperator {
  AND,
  OR,
}
