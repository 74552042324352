import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../common/business/auth/services/auth.service';

/**
 * Router guard that activates the requested route only if the user is logged in.
 * Otherwise, the user is redirected to the login screen.
 */
export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authService: AuthService = inject(AuthService),
  router: Router = inject(Router)
) => {
  return authService.isLoggedIn().pipe(
    map((loggedIn) => {
      if (loggedIn) {
        return true;
      } else {
        router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });

        return false;
      }
    })
  );
};
