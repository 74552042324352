import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Interface to represent a NavigationHistory entry.
 */
export interface NavigationHistory {
  title: string;
  subtitle?: string;
  path: string[];
}

/**
 * Service to keep track of navigation history.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private static readonly maxLength = 10;

  private static readonly storageKey = 'abs-navigation-history';

  /**
   * The full history of accessed routes. Get from session storage to persist between refreshes.
   */
  history: NavigationHistory[] = JSON.parse(sessionStorage.getItem(NavigationHistoryService.storageKey) || 'null') || [];

  /**
   * The current active route.
   */
  current: NavigationHistory | null = null;

  /**
   * The last saved route data.
   */
  lastSaved: NavigationHistory | null = null;

  constructor(private route: ActivatedRoute) {}

  /**
   * Handle navigation complete on the "primary" router outlet only.
   */
  navigationComplete(): void {
    // Always get the first child route. We use root routes only in the history
    let route = this.route;

    // Find the first route definition in the hierarchy that is to be added to the history or get the leaf component
    while (!route.snapshot?.data.history && route.firstChild) {
      route = route.firstChild;
    }

    const newData = {
      title: route.snapshot?.data.title,
      path: route.snapshot?.pathFromRoot.flatMap((segment) => segment.url).map((segment) => segment.path),
    };

    // Add the previous route to the history if not the same as the current one and not the same as the last saved one
    if (this.current && this.current.path.join() !== newData.path?.join() && this.current.path.join() !== this.lastSaved?.path.join()) {
      const length = this.history.unshift(this.current);
      sessionStorage.setItem(NavigationHistoryService.storageKey, JSON.stringify(this.history));
      this.lastSaved = this.current;
      this.current = null;

      if (length > NavigationHistoryService.maxLength) {
        this.history.pop();
      }
    }

    // Save the current route only if history is enabled
    if (route.snapshot?.data.history) {
      this.current = newData;
    }
  }

  /**
   * Set the subtitle to display on the history for the current route.
   *
   * @param subtitle the subtitle to apply
   */
  setCurrentSubtitle(subtitle: string): void {
    if (this.current) {
      this.current.subtitle = subtitle;
    }
  }

  /**
   * Clear the navigation history.
   */
  clear() {
    this.lastSaved = null;
    this.current = null;
    this.history = [];
    sessionStorage.setItem(NavigationHistoryService.storageKey, JSON.stringify(this.history));
  }
}
