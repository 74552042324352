import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';
import { Pageable } from './pageable';

/**
 * Abstraction of datasource with pagination
 */
export abstract class PageableDataSource<T> extends DataSource<T> {
  cachedData = Array.from<T>({ length: 0 });
  currentData: T[] = [];
  dataStream = new BehaviorSubject<T[]>(this.cachedData);
  private subscription = new Subscription();

  nextPage = 0;
  lastPage = 0;
  hasData = true;

  /**
   * Whether currently loading data from the server.
   */
  _loading = false;

  /**
   * Getter for the loading flag.
   */
  get loading() {
    return this._loading;
  }

  /**
   * Loading setter to also send loading event.
   */
  set loading(value: boolean) {
    this._loading = value;
    this.loadingSubject.next(value);
  }

  /**
   * The total elements on this
   */
  totalElements = 0;

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    public limit: number,
    public startPage: number = 0,
  ) {
    super();

    this.loading = true;

    // Load all pages up to the startPage argument
    // This is used for pages where the user might open the list at a specific page, but since we use
    // infite scroll, we should load all pages, not only the requested one
    const obs: Observable<Pageable<T>>[] = [];
    for (let index = 0; index <= this.startPage; index++) {
      obs.push(this.fetch(index, limit));
    }

    forkJoin(obs)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((datas) => {
        datas.forEach((data) => this.handleData(data));
        this.nextPage = startPage + 1;
        this.lastPage = startPage;
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const currentPage = this.getPageByIndex(range.end);

        if (currentPage > this.lastPage) {
          this.lastPage = this.nextPage = currentPage;
          this.fetchPage();
        }
      }),
    );
    return this.dataStream;
  }

  disconnect(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Fetches the data of specific page
   */
  fetchPage(): void {
    this.fetch(this.nextPage, this.limit).subscribe({
      next: (data) => this.handleData(data),
      // TODO: error treatment
      error: () => null,
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   * Gets the current page
   * @param i
   */
  private getPageByIndex(i: number): number {
    const page = Math.floor(i / this.limit);
    return page + this.startPage;
  }

  /**
   * Fetches page data
   * @param page
   * @param limit
   */
  abstract fetch(page: number, limit: number): Observable<Pageable<T>>;

  /**
   * Handles the data to get the sources from super search response
   * @param data
   */
  handleData(data: Pageable<T>): void {
    if (data.content.length === 0 && this.cachedData.length === 0) {
      this.hasData = false;
    }
    this.totalElements = data.totalElements;
    this.cachedData = this.cachedData.concat(data.content);
    this.currentData = data.content;
    this.dataStream.next(this.cachedData);
  }
}
